<template>
  <div
    class="bg-white border border-primary border-2 p-4 position-relative"
    :class="$style[`min-height-100`]"
  >
    <!--                             <div v-if="bonusUse.length==0" class="h4">尚無購物金使用資訊</div> -->
    <div
      v-for="(row,index) in bonusUsedList"
      :key="index"
      class="my-2"
    >
      <div
        class="bg-primary text-white d-flex justify-content-between p-2 py-md-3 px-md-4 rounded"
      >
        <span><span class="d-none d-md-inline me-2">銷售編號：</span>{{ row.saleID }}</span>
        <span class="d-none d-lg-inline"><span>使用日：</span>{{ row.saleDate??'-' }}</span>
        <span class="d-inline d-lg-none">{{ row.saleDate ? row.saleDate.split(' ')[0]:'-' }}</span>
        <span>全商品使用：{{ row.couponUse ?? 0 }}</span>
        <span>配件商品使用：{{ row.couponPartsUse ?? 0 }}</span>
      </div>
      <!--      <div class="w-100 bg-white collapse border"
                                     v-bind:id="'cu' + index">
                                    <div class="container-lg">
                                        <div v-for="(coupon, index) in bonusUse"
                                             class="row align-items-center mb-3 mb-md-2">
                                            <div class="col-6">{{coupon.description}}</div>
                                            <div class="text-center col-2 col-md-2 my-4 d-none d-md-block">{{coupon.price}}</div>
                                            <div class="text-center col-2 col-md-2 my-4">{{coupon.qty}}</div>
                                            <div class="text-end col-4 col-lg-2 my-4">{{coupon.price*coupon.qty}}</div>
                                        </div>
                                        <hr>
                                        <div class="row my-4">
                                            <span class="col-8 ">總金額</span>
                                            <span class="col-4 text-end">{{order.originalPrice}}</span>
                                        </div>
                                        <div v-if="order.coupon"
                                             class="row my-4">
                                            <span class="col-8">使用折扣碼
                                                {{order.coupon.name}}</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.coupon.price}}</span>
                                        </div>
                                        <div v-if="order.usedPoints"
                                             class="row my-4">
                                            <span class="col-8">使用購物金折抵</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.usedPoints}}</span>
                                        </div>
                                        <div v-if="order.discount"
                                             class="row my-4">
                                            <span class="col-8">活動折抵
                                                {{order.discount.name}}</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.discount.price}}</span>
                                        </div>
                                        <hr>
                                        <div class="row my-4">
                                            <span class="col-8">總金額</span>
                                            <span class="col-4 text-end">{{order.finalPrice}}</span>
                                        </div>

                                    </div>
                                </div> -->
    </div>

    <!-- empty msg -->
    <div
      v-if="bonusUsedList.length===0"
      class="position-absolute top-50 start-50 translate-middle d-flex flex-column"
    >
      <span class="d-block mb-2">
        您還未獲得購物金</span>
      <router-link
        to="/product/list"
        class="btn btn-primary text-white rounded-0"
      >
        馬上去逛逛
      </router-link>
    </div>

    <!-- page nav -->
    <PagePanigation
      v-if="!isLoading && bonusUsedList.length!==0"
      class="d-none d-md-flex"
      :current-page="page?.current"
      :total-page="page?.totalPage"
      @setPage="setPage"
    />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, inject, ref, reactive } from 'vue'
import PagePanigation from '@/components/PagePanigation'
export default {
  name: 'MemberBonusUsed',
  components: {
    PagePanigation
  },
  async setup (props) {
    const { getters, dispatch } = useStore()

    const page = reactive({
      current: 1,
      totalPage: 1,
      totalElement: 0
    })

    const pageSize = ref(20)

    const isLoading = computed(() => getters.getIsLoading)
    const memberID = computed(() => getters['member/getMemberID'])
    const bonusUsedList = computed(() => getters['member/getBonusUsedList'])

    const setAlert = inject('setAlert')

    const setPage = (page) => { readBonusUsedList(page) }

    const readBonusUsedList = async (newPage) => {
      const payload = {
        memberID: memberID.value,
        page: newPage || page.current,
        offset: page.current * pageSize.value
      }

      try {
        const response = await dispatch('member/readBonusUsedList', { payload })
        const data = response.data
        page.offset = data.data?.page?.offset || 0
        page.totalElement = data.data?.page?.totalElement || 0
        page.totalPage = data.data?.page?.totalPage || 1
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        page.totalElement = 0
        setAlert(true, false, error)
      }
    }

    await readBonusUsedList()

    return { bonusUsedList, isLoading, setPage, page }
  }
}
</script>
<style lang="scss" module>
.min-height-100{
  min-height: 100%;
}
</style>
